<template>
  <div>
    <div class="top">
      <img src="../../../static/image/left.png" @click="back" alt="" />
      <div class="order_text">《平台寄卖协议》</div>
    </div>
    <div class="jimai" v-html="this.goodsorderButton"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      goodsorderButton: '', //寄卖协议
    };
  },

  // 页面加载
  mounted() {
    let params = {
      token: localStorage.getItem('token'),
    };
    this.$api.goodsorderButton(params).then((res) => {
      if (res.code == 100000) {
        this.goodsorderButton = res.data;
      }
    });
  },

  methods: {
    back() {
      this.$router.back();
    },
  },
};
</script>

<style lang="less" scoped>
.top {
  background-color: #ffffff;
  height: 48px;
  display: flex;
  align-items: center;
  padding-left: 16px;
  img {
    width: 11px;
    height: 20px;
  }
  .order_text {
    font-size: 18px;
    margin-left: 28%;
  }
}

.jimai {
  padding-left: 10px;
  padding-right: 10px;
}
</style>
